import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Holzbildwerk1 from "../components/holzbildwerk1"
import Holzbildwerk2 from "../components/holzbildwerk2"
import Holzbildwerk3 from "../components/holzbildwerk3"
import Holzbildwerk4 from "../components/holzbildwerk4"

import "./index.css"

export default function HolzbildwerkePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Holzbildwerk"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Holzbildwerk"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Holzbildwerke</h1>
        <h2 className="referenceH2">
          Kath. Pfarrkirche St. Margareta, Irfersdorf, Lkr. Beilngries,
          Ölberggruppe um 1500
        </h2>
        <h3 className="referenceH3">
          Konservierung mit Hausenblasenleim, reduzierte Kreidegrundergänzung,
          minimale Retusche, Holzstabilisierung, holzbildhauerische Ergänzung
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Holzbildwerk1">
              <Holzbildwerk1 id="Holzbildwerk1" />
              Die Ölberggruppe
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Holzbildwerk2">
              <Holzbildwerk2 id="Holzbildwerk2" />
              Vor der Reinigung mit Japanpapierabklebung
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Holzbildwerk3">
              <Holzbildwerk3 id="Holzbildwerk3" />
              Nach der Reinigung und vor der Fehlstellenergänzung
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Holzbildwerk4">
              <Holzbildwerk4 id="Holzbildwerk4" />
              Nach der Ergänzung der Attribute
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Die Ölbergruppe stand ehemals am Friedhof von Irfersdorf in einem
              kleinen Gebäude, das heute nicht mehr vorhanden ist. Nach der
              Demontage des Ölbergs verbrachten die Kunstwerke mehrere
              Jahrzehnte, unter schlechten klimatischen Bedingungen, auf dem
              Dachboden.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Durch die Witterungseinflüsse sind Risse im Holz entstanden. Nach
              der Wiederentdeckung der Ölberggruppe stand zunächst der
              Substanzerhalt im Vordergrund. Dieser sollte mit Hausenblasenleim
              erfolgen. Einige Hände und Attribute fehlten. Den Bestand der
              Farbfassung und des Holzträgers konnte man an allen Figuren als
              äußerst schlecht bezeichnen. Die Farbfassungen waren vergraut und
              standen hoch. Dr. Emanuel Braun sah vor, die Gruppe wieder in
              Friedhofsnähe aufzustellen, da die Ölbergszene ein Beispiel für
              die mittelalterliche Liturgie der jeweiligen Gemeinden ist.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Die ästhetische Maßnahme hatte zum Ziel, die Figurengruppe als
              Andachtsbild wieder wirken zu lassen, ohne dabei zu stark in den
              historischen Fassungsbestand einzugreifen. Deshalb sollten sich
              die farblichen Ergänzungen nur auf bedeutsame Stellen beschränken,
              welche ansonsten die Ästhetik stark beeinträchtigen würden. Diese
              betrafen beim Petrus beispielsweise hauptsächlich die Fehlstellen
              an der Nase und am Mund.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Nach der minimalen Kreidegrundkittung wurden die ausgewählten
              Fehlstellen mit Punktretusche geschlossen. Die Hand des Petrus und
              sein Schwert sind verlorengegangen. Damit man den Apostel wieder
              als den Jünger Petrus identifizieren kann, wurden das Schwert und
              die dazugehörige Hand holzbildhauerisch von Stefan Link ergänzt.
              Anschließend wurde das Schwert versilbert und das Inkarnat der
              Hand mit einer Temperafarbe gefasst.
            </p>

            <h4 className="referenceH4">Standort</h4>
            <p className="referenceText">
              Es wurde ein neuer Platz für die Skulpturengruppe neben dem
              Leichenhaus geschaffen. Dort stehen sie in einem überdachten Raum,
              der mit einer Glasplatte geschützt ist. Da die Skulpturengruppe
              wieder in den baulichen Bestand in Friedhofsnähe aufgenommen
              worden war, kann die Tradition an das Gedenken vom Gebet Jesu am
              Ölberg aufrecht erhalten werden. So wird der Substanzerhalt des
              Anschauungsobjektes mit der Pflege des religiösen Brauchtums
              verknüpft.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Kath. Kirchenstiftung Irfersdorf</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Herr Dr. Emanuel Braun, Leiter des Diözesanmuseum Eichstätt,
              Nachfolge Frau Dr. Claudia Grund Landesamt für Denkmalpflege Herr
              Martin Hess.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
